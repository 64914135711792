body {
	background: white;
    font-size: 12pt;
    line-height:18pt;
    font-family:sans-serif;
    margin: 0;
	padding: 0;
    width:100%;
    max-width:100%;
}

body * {
    float:none;
    width:100%;
    max-width:100%;
}

nav.main, nav.secondary, nav.cta-links, nav.shopping-cart,
a.expand, header#site-header .site-search,
.skip-to-content,
.usn_cmp_anchornavigation, .in-this-section,
.usn-notification,
.usn_back-to-top,
.scroll-prompt,
.slides,
form,
.breadcrumb-trail,
nav.sub,
nav.social ul li img,
nav.tabs,
header#site-header #logo img {
	display: none!important;
}

#site-content,
.content-col, .container, .row,
footer#site-footer {
    width: 100%; max-width:100%;
    margin:0; padding:0;
	border: 0;
	float: none!important;
	color: black;
	background: transparent none;
}

header#site-header  {
    margin-bottom:15pt;
    padding-bottom:10pt;
    border-bottom:1pt solid black;
}

header#site-header #logo span {
    font-size:12pt;
}

footer#site-footer {
    margin-top:25pt;
    padding-top:15pt;
    border-top:1pt solid black;
}

div.image, div.video, img, video, #map {
    width:100%; max-width:300px;
}

div.item:not(.item_data-list) {
    margin-bottom:15pt;
}

h1 {
    font-size:22pt;
    line-height:24pt;
}

h2 {
    font-size:20pt;
    line-height:22pt;
}

h3 {
    font-size:18pt;
    line-height:20pt;
}

h4 {
    font-size:16pt;
    line-height:18pt;
}

h5, h6, p.heading {
    font-size:14pt;
    line-height:16pt;
}

.secondary-heading {
    font-style:italic;
}

h1, h2, h3, h4, h5, h6, .heading {
    font-weight:bold;
}

h1, h2, h3, h4, h5, h6, .heading,
p, ul, ol {
    margin:10px 0;
}

a:link,
a:visited {
	color: black;
	font-weight: normal;
    text-decoration: none;
    display:inline;
}

a:link:after,
a:visited:after {
	content: " (" attr(href) ") ";
	color: #4c8bf5!important;
	background: transparent;
	font-weight: bold;
    text-decoration: underline;
    display:inline; margin:0 3px;
}

header#site-header #logo a:link:after,
header#site-header #logo a:visited:after {
    display:none;
}

nav ul {
    list-style:none; margin-left:0; padding-left:0;
}

nav ul li {
    margin-left:0; padding-left:0;
}

nav.footer-navigation ul li {
    display:inline; margin-left:0; padding-left:0;
}

nav.footer-navigation ul li div {
    display:inline;
}

section.agenda {
    .buttons-wrapper {
        display: none;
    }

    .nav.nav-tabs {
        display: none;
    }
}